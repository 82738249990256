import { supportPageLinks } from '@chegg-wt/core/constants/supportPageLinks'
import { Config } from '@chegg-wt/core/config'
import { FooterColumn } from '@chegg-wt/core/types/core'

export const footerLinkColumns: FooterColumn[] = [
  {
    title: 'Company',
    links: [
      {
        name: 'Blog',
        url: `${Config.baseUrl}/resources/blog/`,
      },
      { name: 'Chegg Inc.', url: Config.cheggEnvUrl },
      { name: 'Contact Us', url: supportPageLinks.citationmachine },
      { name: 'Support', url: supportPageLinks.citationmachine },
    ],
  },
  {
    title: 'Resources',
    links: [
      {
        name: 'Citation Machine® Plus',
        url: `${Config.baseUrl}/upgrade`,
      },
      {
        name: 'Citation Guides',
        url: `${Config.baseUrl}/resources/`,
      },
      {
        name: 'APA',
        url: `${Config.baseUrl}/apa`,
      },
      {
        name: 'MLA',
        url: `${Config.baseUrl}/mla`,
      },
      {
        name: 'Chicago Style',
        url: `${Config.baseUrl}/chicago`,
      },
      {
        name: 'Harvard Referencing',
        url: `${Config.baseUrl}/harvard`,
      },
    ],
  },
  {
    title: 'Follow Us',
    links: [
      {
        name: 'Facebook',
        url: 'https://www.facebook.com/Citation-Machine-131684413573248/',
        rel: 'nofollow',
      },
      { name: 'Twitter', url: 'https://twitter.com/citemachine', rel: 'nofollow' },
      {
        name: 'Youtube',
        url: 'https://www.youtube.com/playlist?list=PLllCkartRRdMcUo0gc7uC5cdE2Ce0Vw4R',
        rel: 'nofollow',
      },
    ],
  },
  {
    title: 'Business',
    links: [
      {
        name: 'Terms of Use',
        url: `${Config.cheggEnvUrl}/termsofuse`,
      },
      {
        name: 'Global Privacy Policy',
        url: `${Config.cheggEnvUrl}/privacypolicy`,
      },
      {
        name: 'Cookie Notice',
        url: `${Config.baseUrl}/cookie-notice`,
      },
      {
        name: 'DO NOT SELL MY INFO',
        id: 'ot-sdk-btn',
        className: 'ot-sdk-show-settings',
        url: '#',
      },
    ],
  },
]
