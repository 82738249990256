import { HeadTags } from '@chegg-wt/core/types/core'

export const head: HeadTags = {
  // title should eventually be changed and made page specific instead of app specific
  title: 'Citation Machine®: Format & Generate - APA, MLA, & Chicago',
  metaTags: [
    {
      name: 'description',
      content:
        'Citation Machine® helps students and professionals properly credit the information that they use. Cite sources in APA, MLA, Chicago, Turabian, and Harvard for free.',
    },
  ],
}
