/* eslint-disable import/prefer-default-export */
import { Config } from '@chegg-wt/core/config'
import { configureSegment } from '@chegg-wt/core/utils/routes'
import { getActualStyleId } from '@chegg-wt/core/utils/styles'
import { getSegmentFn, getIsExactFn, getSpecialSegmentFn } from '@chegg-wt/core/utils/url'
import { GetFromQuery, IsExact } from '@chegg-wt/core/types/core'

export const matchSourceSegment = configureSegment('cite-a-:source+')

export const sourceSegmentRoutes = [
  '/[style]/[source]',
  '/[style]/[source]/search',
  '/[style]/[source]/confirm',
  '/[style]/[source]/new',
  '/[style]/[source]/custom',
  '/[style]/[source]/edit',
]

export const getSourceFromQuery: GetFromQuery = getSegmentFn({
  segmentRoutes: sourceSegmentRoutes,
  matchSegment: matchSourceSegment,
  paramName: 'source',
})

export const isSourceExact: IsExact = getIsExactFn({
  segmentRoutes: sourceSegmentRoutes,
  matchSegment: matchSourceSegment,
})

export const specialSegmentRoutes = [
  '/apa',
  '/chicago',
  '/mla',
  '/apa/cite-a-book',
  '/apa/cite-a-journal',
  '/apa/cite-a-website',
  '/mla/cite-a-book',
  '/mla/cite-a-journal',
  '/mla/cite-a-website',
]

export const matchSpecialSegment = configureSegment('/:style/cite-a-:source')
export const matchSeoStyleSegment = configureSegment('/:style')

const getSpecialSourceFromQuery = getSpecialSegmentFn({
  segmentRoutes: specialSegmentRoutes,
  matchSegment: matchSpecialSegment,
  paramName: 'source',
})

const getSpecialStyleFromQuery: GetFromQuery = (router, _query, wrapByAlias = true) => {
  const matchedValue = getSpecialSegmentFn({
    segmentRoutes: specialSegmentRoutes,
    matchSegment: matchSpecialSegment,
    paramName: 'style',
  })(router)

  return wrapByAlias ? getActualStyleId(matchedValue) : matchedValue
}
const getSeoStyleFromQuery: GetFromQuery = (router, _query, wrapByAlias = true) => {
  const matchedValue = getSpecialSegmentFn({
    segmentRoutes: specialSegmentRoutes,
    matchSegment: matchSeoStyleSegment,
    paramName: 'style',
  })(router)

  return wrapByAlias ? getActualStyleId(matchedValue) : matchedValue
}

const determineSpecialSegmentRoutes = (asPath: string) =>
  specialSegmentRoutes.some((route) => route === asPath)

export const chooseGetSourceFromQuery = (router) => {
  const { route } = router
  const isSpecialSegmentRoute = determineSpecialSegmentRoutes(route)
  return isSpecialSegmentRoute ? getSpecialSourceFromQuery : getSourceFromQuery
}

export const chooseGetStyleFromQuery = (router) => {
  const { asPath } = router
  const seoStyles = ['/apa', '/chicago', '/mla']
  const isSpecialSegmentRoute = determineSpecialSegmentRoutes(asPath)
  if (seoStyles.includes(asPath)) {
    return getSeoStyleFromQuery
  }
  return isSpecialSegmentRoute ? getSpecialStyleFromQuery : undefined
}

export const getCanonicalUrl = (route: string, query: any): string => {
  const isSpecialSegmentRoute = determineSpecialSegmentRoutes(route)
  if (isSpecialSegmentRoute) return `${Config.baseUrl}${route}`
  if (route === '/') return Config.baseUrl
  if (!query.style) return null
  if (!query.source) return `${Config.baseUrl}/${query.style}`
  return `${Config.baseUrl}/${query.style}/${query.source}`
}
